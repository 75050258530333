exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-avisol-js": () => import("./../../../src/pages/avisol.js" /* webpackChunkName: "component---src-pages-avisol-js" */),
  "component---src-pages-desenho-js": () => import("./../../../src/pages/desenho.js" /* webpackChunkName: "component---src-pages-desenho-js" */),
  "component---src-pages-editorial-js": () => import("./../../../src/pages/editorial.js" /* webpackChunkName: "component---src-pages-editorial-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-ilustracion-js": () => import("./../../../src/pages/ilustracion.js" /* webpackChunkName: "component---src-pages-ilustracion-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tipografia-js": () => import("./../../../src/pages/tipografia.js" /* webpackChunkName: "component---src-pages-tipografia-js" */)
}

